import http from "@/apps/shared/baseService/config";

const base = {
  rota: "balance-report-seller",
  auth: 'seller'
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list-my-withdraw-requests`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/${base.auth}/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    listar_withdraws: ({ commit, dispatch, getters }) => {
      if (localStorage.getItem("withdraws")) {
        commit("set_withdraws", JSON.parse(localStorage.getItem("withdraws")));
      } else {
        dispatch("enableLoading");
      }
      service
        .listar(getters.get_withdraws_filtros)
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem("withdraws", JSON.stringify(res.data));
          commit("set_withdraws", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_withdraw: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_withdraw, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_withdraw", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_withdraws");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_withdraw: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_withdraw, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_withdraw", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_withdraws");
          commit("set_modal_view_withdraw", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_withdraw: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_withdraws");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_withdraw: ({ commit }, payload) => {
      if (payload) {
        commit("set_withdraw", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_withdraw", true);
    },
    fecha_modal_view_withdraw: ({ commit }) => {
      commit("set_withdraw", {});
      commit("setLinks", []);
      commit("set_modal_view_withdraw", false);
    }
  },
  state: {
    withdraw: {},
    withdraws: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    withdraws_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_withdraw: false
  },
  mutations: {
    set_withdraw: (state, payload) => (state.withdraw = payload),
    set_withdraws: (state, payload) => (state.withdraws = payload),
    set_modal_view_withdraw: (state, payload) =>
      (state.modal_view_withdraw = payload)
  },
  getters: {
    get_withdraw: state => state.withdraw,
    get_withdraws: state => state.withdraws,
    get_modal_view_withdraw: state => state.modal_view_withdraw,
    get_withdraws_filtros: state => state.withdraws_filtros
  }
};

const routes = [
  {
    path: `/alunos`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
