import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { UserLoggedModule } from "@/apps/UserLogged/store";
import { SharedModule } from "@/apps/shared/store";
import { AuthModule } from "@/apps/Auth/store";
import Seller from "./apps/Seller/meta";
import Sales from "./apps/Sales/meta";
import BalanceReport from "./apps/BalanceReport/meta";

export const store = new Vuex.Store({
  modules: {
    UserLoggedModule,
    AuthModule,
    SharedModule,
    seller: Seller.module,
    sales: Sales.module,
    BalanceReport: BalanceReport.module,
  }
});

export default store;
