import http from "@/apps/shared/baseService/config";

class Service {
  auth(payload) {
    return http.post(`/login-seller`, payload);
  }
  signup(payload) {
    return http.post(`/signup-seller`, payload);
  }
  forgotPassword(email) {
    return http.post(`/forgot-password`, email);
  }
  forgotPasswordFire(payload) {
    return http.post(`/forgot-password-fire/${payload.token}`, payload);
  }
  activateAccount(id) {
    return http.post(`/activate-account/${id}`);
  }
}

export default new Service();
