class Helper {
  formataSaldo(saldo) {
    const saldo_convert = parseFloat(saldo);
    return saldo_convert.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }
  validateField(val) {
    if (val) {
      return val;
    } else {
      return "";
    }
  }
}

export default Helper;
