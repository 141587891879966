import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { logged_user_routes } from "./apps/UserLogged/router";
import { auth_routes } from "./apps/Auth/router";
import Seller from "./apps/Seller/meta";
import Sales from "./apps/Sales/meta";
import BalanceReport from "./apps/BalanceReport/meta";

const routes = [
  ...logged_user_routes,
  ...auth_routes,
  ...Seller.routes,
  ...Sales.routes,
  ...BalanceReport.routes,
];

export const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
