<template>
  <!-- SNACKBAR DE CONFIRMAÇÃO GLOBAL -->
  <v-dialog
    width="300"
    persistent
    v-model="getModalConfirmAction.opened"
    transition="slide-x-transition"
  >
    <v-card
      dark
      :color="$theme.primary"
      class="card expande-horizontal centraliza"
    >
      <div class="expande-horizontal centraliza wrap">
        <v-flex xs12>
          <div class="expande-horizontal centraliza">
            <v-avatar class="elevation-0">
              <v-icon class="fadeIn" color="white">
                mdi-check-circle-outline
              </v-icon>
            </v-avatar>
          </div>
        </v-flex>

        <v-flex class="mt-3 mb-3" xs12>
          <div class="expande-horizontal centraliza">
            <span class="fonte text-center">
              {{ getModalConfirmAction.message }}
            </span>
          </div>
        </v-flex>

        <v-flex xs12>
          <div class="expande-horizontal semquebra">
            <v-flex class="pa-2" xs6>
              <v-btn
                text
                color="default"
                block
                @click.prevent="unsetConfirmAction"
              >
                <span class="fonte fonteMini font-weight-thin">
                  Não
                </span>
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6>
              <v-btn
                class="fonte"
                color="white"
                rounded
                block
                @click.prevent="sim()"
                outlined
              >
                Sim
              </v-btn>
            </v-flex>
          </div>
        </v-flex>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getModalConfirmAction"])
  },
  methods: {
    ...mapActions(["unsetConfirmAction"]),
    sim() {
      if (this.getModalConfirmAction.isScoped) {
        this.getModalConfirmAction.action();
        this.unsetConfirmAction();
      } else {
        this.$store.dispatch(
          this.getModalConfirmAction.action,
          this.getModalConfirmAction.action_value
        );
        this.unsetConfirmAction();
      }
    }
  }
};
</script>

<style>
.card {
  padding: 12px;
  width: 300px;
  overflow: hidden;
  border-radius: 10px;
}
.expande-horizontal {
  display: flex;
  width: 100%;
}
</style>
