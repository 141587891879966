import http from "@/apps/shared/baseService/config";

const base = {
  rota: "subscription",
  auth: 'seller'
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  excluir_muitos: data => http.post(`/${base.auth}/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    list_sales: ({ commit, dispatch, getters }) => {
      if (localStorage.getItem("sales")) {
        commit("set_sales", JSON.parse(localStorage.getItem("sales")));
      } else {
        dispatch("enableLoading");
      }
      service
        .listar(getters.get_sales_filtros)
        .then(res => {
          dispatch("disableLoading");
          localStorage.setItem("sales", JSON.stringify(res.data));
          commit("set_sales", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_sale: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({ ...getters.get_sale, imagens: getters.getLinks })
        .then(res => {
          dispatch("disableLoading");
          commit("set_sale", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("list_sales");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_sale: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({ ...getters.get_sale, imagens: getters.getLinks })
        .then(() => {
          dispatch("disableLoading");
          commit("set_sale", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("list_sales");
          commit("set_modal_view_sale", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_sale: ({ dispatch }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("list_sales");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_sale: ({ commit }, payload) => {
      if (payload) {
        commit("set_sale", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_sale", true);
    },
    fecha_modal_view_sale: ({ commit }) => {
      commit("set_sale", {});
      commit("setLinks", []);
      commit("set_modal_view_sale", false);
    }
  },
  state: {
    sale: {},
    sales: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    sales_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_sale: false
  },
  mutations: {
    set_sale: (state, payload) => (state.sale = payload),
    set_sales: (state, payload) => (state.sales = payload),
    set_modal_view_sale: (state, payload) =>
      (state.modal_view_sale = payload)
  },
  getters: {
    get_sale: state => state.sale,
    get_sales: state => state.sales,
    get_modal_view_sale: state => state.modal_view_sale,
    get_sales_filtros: state => state.sales_filtros
  }
};

const routes = [
  {
    path: `/relatorio`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
